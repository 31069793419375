<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <div class="ant_body">
    <div class="ant-page-header-search">
      <a-form ref="formQueryRef" layout="inline" :model="form">
        <a-form-item :wrapperCol="{flex:'auto'}">
          <a-cascader v-model:value="form.startAddress" :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setStartAddress" placeholder="请选择起始地" />
        </a-form-item>
        <a-form-item :wrapperCol="{flex:'auto'}" name="endAddress">
          <a-cascader v-model:value="form.endAddress" :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setEndAddress" placeholder="请选择目的地" />
        </a-form-item>
        <a-form-item>
          <a-select v-model:value="form.driver.value" class="ransport_search_input">
            <a-select-option v-for="city  in department" :key="city[1]"> {{ city[0] }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-input-group class="ant-page-header-search-input">
            <a-range-picker v-model:value="form.timeLimit" valueFormat="YYYY/MM" size="large" />
          </a-input-group>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" style=" height: 40px;" @click="getEchartsData">
            查询
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
  <div class="ant_body">
    <div class="about" id="main"></div>
  </div>
</template>

<script>
import transportType from '../../transport/TransportType/TransportType.json'
import * as echarts from 'echarts'
import { kanbanVehicle } from '@/api/dataCenter/kanban'
import { reactive, toRefs, onMounted } from 'vue'
export default {
  data () {
    return {
      department: transportType.department
    }
  },
  setup () {
    const state = reactive({
      title: '区域发运量',
      series: [],
      form: {
        driver: { label: '', value: '' },
        timeLimit: []
      },
      xAxis: [],
      label: {
        show: true,
        position: 'insideTop',
        distance: 2,
        align: 'center',
        verticalAlign: 'right',
        rotate: 0,
        formatter: '{c}  {name|{a}}',
        fontSize: 14,
        rich: {
          name: {
            textBorderColor: '#fff'
          }
        }
      },
      emphasis: {
        focus: 'series'
      }
    })

    const loadData = () => {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('main'))
      // 绘制图表
      myChart.setOption({
        color: ['#e5323e', '#006699', '#4cabce'],
        title: {
          text: state.title
        },
        legend: {
          data: ['第一排', 'Steppe', 'Desert', 'Wetland']
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          data: state.xAxis
        },
        yAxis: {},

        series: state.series
      })
    }
    const getEchartsData = () => {
      state.loading = true
      const queryDTO = state.form
      kanbanVehicle({ queryDTO }).then((res) => {
        if (res.code === 10000) {
          state.xAxis = res.data.xaxis
          state.series = res.data.series
          state.series.forEach((item, index) => {
            item.type = 'bar'
            item.label = state.label
            item.emphasis = state.emphasis
          })
          loadData()
        }
        state.uploading = false
      })
    }
    const setEndAddress = (value, selectedOptions) => {
      state.form.endAddress.province = value[0]
      state.form.endAddress.city = value[1]
      state.form.endAddress.area = value[2]
    }
    const setStartAddress = (value, selectedOptions) => {
      state.form.startAddress.province = value[0]
      state.form.startAddress.city = value[1]
      state.form.startAddress.area = value[2]
    }
    onMounted(getEchartsData)
    return {
      ...toRefs(state),
      loadData,
      setEndAddress,
      setStartAddress,
      getEchartsData

    }
  }
}
</script>
